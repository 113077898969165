import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import CaseDetails from "../sections/gts/CaseDetails";
import CTA from "../sections/gts/CTA";

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="14">
                <Title variant="hero">
                  The Ultimate Tool for Operational Success
                </Title>
                <Text>How CMMS Software Can Make All the Difference</Text>
                <p>
                  CMMS software, or Computerized Maintenance Management Systems,
                  is an excellent solution for businesses who want to take full,
                  yet easy, control of their maintenance operations. This type
                  of cloud-based management software makes tracking, analyzing,
                  and even establishing daily tasks extremely efficient while
                  allowing businesses to reach their short- and long-term goals
                  via key management tools
                </p>
                <p>
                  But what is CMMS software? And how can it make such a
                  difference in your business?
                </p>
                <p>Keep reading to find out!</p>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="14">
                <Title variant="hero">What is CMMS Software?</Title>
                <p>
                  This maintenance management software allows organization
                  owners, managers, and even working staff to effectively run
                  day-to-day work orders, preventive management duties,
                  inventory asset administration, and auditing procedures. This
                  not only puts an entire department or organization into a
                  controlled structure, but also allows you to gain better
                  insights through tracking and reports. These types of insights
                  include maintenance data, running costs, and trends.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseDetails />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
