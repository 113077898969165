import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { A, Section, Box, Title, Text, Button, List } from "../../components/Core";

import imgCase1 from "../../assets/image/jpeg/case-details--details.jpg";
import imgCase2 from "../../assets/image/jpeg/case-details-half-single.jpg";
import imgCase3 from "../../assets/image/jpeg/case-details-half-single-2.jpg";

const ImgContainer = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
`;

const CaseDetails = () => (
  <>
    <Section className="position-relative" borderBottom="1px solid #eae9f2;">
      <Container>
        <ImgContainer className="text-center" pb={["50px", null, "75px"]}>
          <img src={imgCase1} alt="" className="img-fluid" />
        </ImgContainer>

        <Row>
          <Col lg="12" xl="10" className="offset-xl-1">
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card">
                So, Why is CMMS Software it So Beneficial?
              </Title>
              <div className="mb-5">
                <p>
                  CMMS software isn’t only a simple organizational tool, but a
                  valuable metric that can effectively help you reduce costs,
                  streamline business processes, and make insightful decisions
                  as well. With this bird’s eye view, you can track completed,
                  pending, and due maintenance tasks, staff KPIs, inventory
                  stock and costs, and so much more from one user-friendly
                  system.
                </p>
                <p>
                  As a business manager, you’re likely already aware of the many
                  facets involved in overall productivity. Over-expenditure and
                  overtime can be traced back to a lack of preventive
                  maintenance, work order confusion, stock shortages – the list
                  goes on. This makes taking control a crucial part of your
                  daily operations
                </p>
                <p>
                  With CMMS software at your disposal, however, you can schedule
                  preventive maintenance tasks, create organized work orders,
                  keep track of inventory stock, and make insightful changes
                  within your company based on data-driven statistics. This will
                  ultimately help you identify areas of improvement and cut
                  unnecessary costs to enhance your bottom line.
                </p>
                <p> But it doesn’t stop there… </p>
                <p>
                  Multiple research studies and surveys have found that an
                  organized, well-managed workplace greatly increases staff
                  productivity. Staff performance is quite a vast concept with
                  multiple facets relating to overall productivity, but one
                  element always seems to make an appearance in decreased
                  productivity – workplace culture.
                </p>
                <p>
                  If your company has an excellent culture of organized
                  procedures, open communication, and trackable data, your
                  employees are more likely to improve their productivity. Why?
                  Because, like you, they have a transparent view of their daily
                  tasks and how to operate within the company. Furthermore,
                  organized work order systems can decrease the likelihood of
                  misunderstandings – which is one of the biggest concerns for
                  most businesses.
                </p>
                <p>
                  CMMS software allows both staff and managers to make requests,
                  adhere to deadlines, and schedule priority work in a space
                  where everything is on full display. Consequently, employees
                  will have increased accountability and motivation to display
                  their talents and productivity for all to see.
                </p>
              </div>
            </Box>
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                Identifying Ideal Maintenance Procedures
              </Title>
              <div className="mb-lg-4">
                <List>
                  <p>
                    Before you can start improving your maintenance processes,
                    you need to determine what you would like to see. In other
                    words, you need to establish maintenance goals. The biggest
                    advantage of this is that you’ll immediately be able to
                    determine which maintenance tasks take priority, and how
                    finding a structuring solution will help drive the bottom
                    line.
                  </p>
                  <p>
                    There are, of course, certain elements that greatly define
                    these goals. You can ask yourself these questions to make
                    better sense of what your goals should be:
                  </p>

                  <li>
                    {" "}
                    What should my return on investment look like regarding
                    maintenance?
                  </li>
                  <li>What are my current expenditures?</li>
                  <li>How much should I save to reach my goals?</li>
                  <li>
                    How much time is wasted due to inventory backorders,
                    equipment downtime, and problem solving?
                  </li>
                  <li>Where do I lack productivity most?</li>
                  <li>Are my compliance procedures up to scratch?</li>
                  <li>Where am I experiencing the most downtime?</li>
                  <li>Are my safety standards where they should be?</li>
                  <li>
                    Do I have full transparency and control over my inventory?
                  </li>
                  <li>
                    Do I have preventive maintenance processes in place?
                  </li>
                  <p>
                    Answering these questions will give you a framework of what
                    you’re dealing with. After all, you can’t find a solution if
                    you don’t know what or where the problem is. From here, you
                    can start arranging the puzzle pieces of asset productivity
                    enhancement solutions.
                  </p>
                </List>
              </div>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mb-lg-0 mb-30">
            <Box>
              <img src={imgCase2} alt="" className="img-fluid" />
            </Box>
          </Col>
          <Col lg="6">
            <Box>
              <img src={imgCase3} alt="" className="img-fluid" />
            </Box>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12" xl="10" className="offset-xl-1 pt-4">
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                Finding the Best CMMS Software Solution
              </Title>
              <Text variant="small" mb={3}>
                There are various CMMS software choices for you to analyze and
                determine which fits best into your identified solution. With
                your list of established goals, you can now start evaluating
                certain factors of CMMS software and see if it aligns with what
                you need. These factors include:
              </Text>
              <List>
                <li>Operational efficiency</li>
                <li>Ease of accessibility.</li>
                <li> User-friendliness</li>
                <li> Data storage and security</li>
                <li> Integration</li>
                <li> Continuous software development</li>
                <li> Customer support</li>
                <li> Software training and tips</li>
                <li> Mobile compatibility</li>
              </List>
            </Box>
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                The Big Proposal – Suggesting CMMS Software to Your Team
              </Title>
              <Text variant="small">
                It’s a well-known fact that companies don’t necessarily take
                easily into change – especially if they haven’t used any form of
                maintenance management software before. If you’re a manager,
                team member, or executive and you’ve established that CMMS
                software is the best option, you now face the challenging tasks
                of relaying all of this information to your superiors. time and
                maintainability.
              </Text>
              <Text variant="small">
                Not only that, but you now have to try and convince them that
                this truly is worth the “big shift” from what they’re used to.
                This will be no walk in the park, because an idea has to be
                exemplary in order for them to even consider changing the course
                of their existing operations. Not to worry though, because
                you’ll have all the information ready to showcase your new-found
                solution.
              </Text>
              <Text variant="small">
                To make the process a little easier, here are some guidelines
                you may want to consider:
              </Text>
              <List>
                <li>
                  Step 1: Gather all of the data you’ve accumulated so far in
                  terms of problem areas. Try to add a little statistic here and
                  there if you really want to state your case.
                </li>
                <li>
                  Step 2: Gather all identified goals and solutions to these
                  problem areas.
                </li>

                <li>
                  Step 3: Create content that clearly aligns CMMS software with
                  your intended goals
                </li>
                <li>
                  Step 4: Schedule a meeting. For now, only propose your
                  solution to your direct superiors. This way, they’ll be there
                  to back you up in bigger, more detailed meetings.
                </li>
                <li>
                  Step 5: Attend every gathering with full confidence knowing
                  this is the solution that suits the company’s needs. Your
                  superiors will notice the research and effort you have put
                  into finding a way to streamline business processes, and they
                  may realize its significance
                </li>
                <li>
                  Step 6: Contact us to schedule a demo and invite your
                  superiors to join. Alternatively, you can start a free trial
                  to test the solution in real-time
                </li>
              </List>
            </Box>
            <Box>
            <A href="/contact">
              <Button>Book a demo</Button>
              </A>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseDetails;
